import React from 'react';
import "../styles/banner.css"

const BannerAbout = () => {
    return (
        <div className="Banner BannerAbout">
        </div>
    );
};

export default BannerAbout;