import React from 'react';

const Footer = () => {
    return (
        <div className='footer'>
     
            <img src ="../logo/kasa_white.svg" alt="Kaza-white-logo"/>

            
         
            <div>
            © 2020 Kasa.  All rights reserved
            </div>
        </div>
    );
};

export default Footer;