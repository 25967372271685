import React from 'react';
import "../styles/banner.css"

const Banner = () => {
    return (
        <div className="Banner Banner_accueil">
            <h1>Chez vous, partout et ailleurs</h1>
        </div>
    );
};

export default Banner;









